// @font-face {
//   font-family: 'Avenir';
//   src: local('Avenir'), url(../assets/fonts/avenir-regular.otf) format('opentype');
// }

// @font-face {
//   font-weight: 200;
//   font-family: 'Avenir';
//   src: local('Avenir'), url(../assets/fonts/avenir-semi-bold.otf) format('opentype');
// }

// @font-face {
//   font-weight: 300;
//   font-family: 'Avenir';
//   src: local('Avenir'), url(../assets/fonts/avenir-bold.otf) format('opentype');
// }

@font-face {
  // font-weight: 450;
  // font-family: 'Circular Std';
  // src: local('CircularStd-Book'),
  // url('#{$font-path}/Circular-Std/CircularStd-Book.otf') format('opentype');

  font-family: 'Circular Std';
  font-weight: 450;
  src: local('CircularStd-Book'), url('../assets/fonts/Circular-Std/CircularStd-Book.eot');
  src: url('../assets/fonts/Circular-Std/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Circular-Std/CircularStd-Book.otf') format('opentype'),
    url('../assets/fonts/Circular-Std/CircularStd-Book.woff2') format('woff2'),
    url('../assets/fonts/Circular-Std/CircularStd-Book.woff') format('woff'),
    url('../assets/fonts/Circular-Std/CircularStd-Book.ttf') format('truetype'),
    url('../assets/fonts/Circular-Std/CircularStd-Book.svg#CircularStd-Book') format('svg');

  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: local('CircularStd-Medium'), url('../assets/fonts/Circular-Std/CircularStd-Medium.eot');
  src: url('../assets/fonts/Circular-Std/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
    //url('../assets/fonts/Circular-Std/CircularStd-Medium.otf') format('opentype'),
    url('../assets/fonts/Circular-Std/CircularStd-Medium.woff2') format('woff2'),
    url('../assets/fonts/Circular-Std/CircularStd-Medium.woff') format('woff'),
    url('../assets/fonts/Circular-Std/CircularStd-Medium.ttf') format('truetype'),
    url('../assets/fonts/Circular-Std/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: local('CircularStd-Bold'), url('../assets/fonts/Circular-Std/CircularStd-Bold.eot');
  src: url('../assets/fonts/Circular-Std/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Circular-Std/CircularStd-Bold.woff2') format('woff2'),
    url('../assets/fonts/Circular-Std/CircularStd-Bold.woff') format('woff'),
    url('../assets/fonts/Circular-Std/CircularStd-Bold.ttf') format('truetype'),
    url('../assets/fonts/Circular-Std/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Paytone One';
  src: local('PaytoneOne'), url('../assets/fonts/Paytone-One/PaytoneOne.eot');
  src: url('../assets/fonts/Paytone-One/PaytoneOne.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Paytone-One/PaytoneOne.woff2') format('woff2'),
    url('../assets/fonts/Paytone-One/PaytoneOne.woff') format('woff'),
    url('../assets/fonts/Paytone-One/PaytoneOne.ttf') format('truetype'),
    url('../assets/fonts/Paytone-One/PaytoneOne.svg#PaytoneOne') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Circular Std';
}

html,
body {
  margin: 0;
  padding: 0;
  // font-family: -apple-system, sans-serif;
  // height: 100%;
  background: #fff;
  overflow-x: hidden;
}

.infy-ads {
  vertical-align: baseline;
}

.container {
  border-radius: 1.25rem;
  background-color: #f9f8f8;
  margin: 8px 0px 0px 0px;
}

.barCompleted {
  border-radius: 1.25rem;
  background-color: #9b26a6;
  height: 5px;
}

.m-style-picker-content,
.m-style-picker-content-vertical {
  padding-top: 0px !important;
}

.m-style-picker-item,
.m-style-picker-item-selected,
.m-style-picker-item-vertical,
.m-style-picker-indicator,
.m-style-picker-indicator-vertical {
  height: 50px !important;
  font-size: 1.25rem !important;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.m-style-picker-item-selected {
  font-weight: 600;
  border-bottom: 1px solid black;
}
.m-style-picker-mask {
  background-image: none !important;
}

.m-style-picker-indicator-vertical {
  border: 0px !important;
}

@media only screen and (max-width: 600px) {
  form {
    width: 100%;
    min-width: initial;
  }
}

.loader {
  border: 6px solid #a1bbf3;
  border-radius: 50%;
  border-top: 6px solid #9b26a6;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
}
.loaderSynthesia {
  border: 6px solid #a1bbf3;
  border-radius: 50%;
  border-top: 6px solid #9b26a6;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.loaderContainer {
  position: relative;
}

.loader-button {
  border: 6px solid #fff;
  border-radius: 50%;
  border-top: 6px solid #9b26a6;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
  margin-left: 30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// .swiper-slide {
//   text-align: center;
//   font-size: 1.125rem;
//   background: #fff;
//   /* Center slide text vertically */
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   -webkit-justify-content: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   -webkit-align-items: center;
//   align-items: center;
// }
// .swiper-wrapper {
//   height: 327px !important;
// }

// .swiper-pagination-bullet {
//   border-radius: 0px !important;
//   margin: 0px !important;
//   height: 20% !important;
// }

// .swiper-pagination-vertical.swiper-pagination-bullets {
//   right: 0px !important;
//   left: 30px !important;
//   top: 10% !important;
//   height: 20%;
// }

.PhoneInputInput {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  width: 100%;
  border: none;

  background: #f8f8f8;
  color: #000;
  line-height: 18px;
  font-weight: 400;
  height: 50px;
  width: 100%;
  border-radius: 8px;
  margin: 0px 0px 16px 0px;
  padding: 0px 0px 0px 16px;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #000;

    font-size: 0.87rem;
    font-weight: 400;
    line-height: 1.125rem;
  }
}

.activeError,
.activeErrorPhone input {
  margin-top: 16px;
  border: 1px solid #e40606 !important;
}

.activePhone .PhoneInputInput:focus {
  // outline-color: #9B26A6 !important;
  // outline-width: 1.5px !important;
  outline: none;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  color: inherit !important;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: none !important;
}

.activePhone .PhoneInputInput {
  margin-top: 16px;
  height: 50px;
  font-size: 0.875rem;
}

.tabsMain {
  @media (min-width: 768px) {
    // height: 50px;
    // font-size: 1.25rem;
  }
}

.rb-tabs-header .rb-tabs-items {
  justify-content: flex-start !important;
}

.rb-tabs .rb-tabs-header .rb-tabs-active-item {
  background-color: #9b26a6 !important;
  height: 3px;
}

.rb-tabs {
  margin-top: 40px;
}

.loadingZip,
.loadingZipHome {
  height: 0;
  width: 0;
  padding: 10px;
  border: 2px solid #9b26a6;
  border-right-color: #a1bbf3;
  border-radius: 22px;
  animation: spinZipLoader 2s linear infinite;
  position: absolute;
  right: 5px;
  top: 8px;
}

.loadingZipHome {
  right: 75px;
  top: 20px;
}

.loaderStopPromote {
  border: 2px solid #a1bbf3;
  border-radius: 50%;
  border-top: 2px solid #9b26a6;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
}

.glider {
  position: absolute;
  display: flex;
  height: 54px;
  width: 200px;
  background-color: #e6eef9;
  z-index: 1;
  border-radius: 15px;
  transition: 0.25s ease-out;
}

.containerTab {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1140px;
  margin: 0 auto;
  .cards {
    padding: 10px;
    max-width: 100%;

    @media (max-width: 767px) {
      margin: 0 auto;
      padding: 10px 0px;
    }
  }
  .card-video-body {
    display: flex;
    padding: 8px;
    padding-top: 35px;
    .video-body {
      width: 130px;
      margin-right: 9px;
      .play {
        svg {
          width: 26px;
          height: 26px;
        }
      }
      .time {
        display: none;
      }
    }
    .video-content {
      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin: 0px;
      }
      .label {
        color: #323b4b;
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: 130%;
      }
      .price,
      span {
        color: #12141d;
        font-size: 16px;
        font-style: normal;
        font-weight: 450;
        line-height: 160%;
      }
    }
    .status {
      &.first {
        display: none;
      }
      position: absolute;
      // height: 2px;
      top: 11px;
      border-radius: 10px 10px 0px 0px;
      h5 {
        margin: 0px;
        right: 0;
        position: absolute;
        height: 22px;
        width: 90px;
        border-radius: 0px 0px 0px 10px;
        display: flex;
        text-transform: uppercase;
        justify-content: center;
        line-height: 20px;
        span {
          margin-left: 7px;
        }
      }
      @media (max-width: 767px) {
        right: 0;
        left: 0;
      }
    }
  }
}

.tabsMain {
  display: flex;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
  border-radius: 15px;
  // @media (min-width: 768px) {
  //   position: absolute;
  //   top: -3.5%;
  //   left: 29%;
  //   width: 55%;
  // }
  // @media (min-width: 1024px) {
  //   top: -3%;
  //   left: 30%;
  //   width: 55%;
  // }
  // @media (min-width: 1280px) {
  //   top: -6%;
  //   left: 25%;
  //   width: 60%;
  // }
  // @media (min-width: 1536px) {
  //   top: -4.5%;
  //   left: 18.5%;
  //   width: 70%;
  // }
}

.tabsMain * {
  z-index: 2;
}

.loaderSurrounding {
  border: 2px solid #a1bbf3;
  border-radius: 50%;
  border-top: 2px solid #9b26a6;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
}

@keyframes spinZipLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cards {
  padding: 0px 8px 0px 8px;
  height: 100%;
}

.card {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 0.6em;
  overflow: hidden;
  // box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
  //   0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  //box-shadow: 0px 16px 18px -4px rgba(24, 39, 75, 0.12);
  transition: all ease 200ms;

  border-radius: 8px;
  border: 1px solid #d9dde3;
  box-shadow: 0px 10px 45px -4px rgba(24, 39, 75, 0.14);
}

.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item {
  width: 50%;
  text-align: center;
  padding: 15px !important;
  color: #8a94a6;
  font-size: 1rem;
  &.active {
    color: #9b26a6;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}
.swiper-wrap {
  margin-bottom: 40px;
  max-width: 1140px;
  margin: auto;
  .swiper-action {
    bottom: 10px;
    top: inherit;
    width: 36px;
    height: 36px;
    background: #9b26a6;
    border-radius: 100px;
    border: none;
    &::after {
      font-size: 14px;
      color: #fff;
    }
  }
  .swiper-button-prev {
    left: 0px;
  }

  .swiper-button-next {
    right: 0px;
  }

  @media (max-width: 767px) {
    .swiper-button-prev {
      left: 50px;
    }

    .swiper-button-next {
      right: 50px;
    }
  }

  .swiper-wrap-inner {
    border: 1px solid #d9dde3;
    border-radius: 12px;
    max-width: 1032px;
    @media (max-width: 767px) {
      border: none;
    }
  }

  .swiper-pagination {
    bottom: 0px !important;
  }

  .swiper-pagination-bullet-active {
    background: #fb9207;
    width: 27px;
    border-radius: 10.5px;
  }
  @media (min-width: 768px) {
    .swiper-action {
      bottom: 0;
      top: 50%;
      margin-top: -18px;
    }
    .swiper-wrap-inner {
      border: none;
    }
  }
}

.banner {
  padding: 20px 0px;
  .swiper-pagination-bullet-active {
    background: #fb9207;
    width: 27px;
    border-radius: 10.5px;
  }
  &__caption {
    text-align: center;
    margin-bottom: 50px;

    &__title {
      font-family: 'Paytone One';
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 100%;
      margin-bottom: 16px;
    }

    &__content {
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 160%;
      margin-bottom: 24px;
    }

    @media (max-width: 767px) {
      margin-top: 50px;
    }
  }

  &__wrap {
    padding: 0px 15px;
  }

  &__images {
    background: url('../assets/images/tv-frame.png') no-repeat;
    background-size: contain;
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 97%;
      @media (min-width: 1024px) {
        padding: 3px;
      }
    }

    .callbox {
      padding: 8px 12px;
      width: auto;
      height: auto;
      position: absolute;
      left: -69px;
      bottom: 30px;
      z-index: 9;
      background: rgba(255, 255, 255, 0.85);
      border: 4.31683px solid #ffffff;
      box-shadow: 0px 9.71287px 64.7525px rgba(0, 0, 0, 0.15);
      backdrop-filter: blur(18.8861px);
      border-radius: 4px;

      &-title {
        color: #4e5d78;
        font-size: 17px;
        margin: 0px;
      }

      &-number {
        color: #9b26a6;
        font-size: 23px;
        margin: 0px;
      }

      @media (max-width: 767px) {
        height: auto;
        left: -30px;
        bottom: 110px;

        &-title {
          font-size: 15px;
        }

        &-number {
          font-size: 20px;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      background: url('../assets/images/tv-frame-qrcode.png') no-repeat;
      right: 0;
      bottom: 0;
      z-index: 2;
      background-size: contain;
      width: 54px;
      height: 54px;
      box-shadow: 0px 9px 60px rgba(0, 0, 0, 0.15);
      margin-bottom: 60px;
      margin-right: -10px;
    }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     background: url('#{$image-path}/tv-frame-number.png') no-repeat;
    //     left: 0;
    //     bottom: 0px;
    //     z-index: 2;
    //     background-size: contain;
    //     width: 137px;
    //     height: 59px;
    //     @include radius-6;
    //     box-shadow: 0px 9px 60px rgba(0, 0, 0, 0.15);
    //     margin-bottom: 90px;
    //     margin-left: -18px;
    // }

    @media (max-width: 567px) {
      width: 307px;
      height: 258px;
      margin: 0 auto;
    }
  }

  @media (min-width: 567px) {
    &__wrap {
      padding: 0px 18%;
    }
  }

  @media (min-width: 992px) {
    padding: 0px 0px;
    margin-bottom: 0px;
    height: calc(100vh - 79px);

    &__caption {
      text-align: left;
      margin-bottom: 0px;

      &__title {
        font-size: 60px;
        line-height: 100%;
        margin-bottom: 20px;
      }

      &__content {
        font-size: 24px;
        line-height: 132%;
        margin-bottom: 32px;
      }
    }

    &__wrap {
      padding: 0px 30px;
    }

    &__images {
      &::before {
        width: 83px;
        height: 83px;
        margin-top: 27px;
        margin-right: -32px;
        box-shadow: 0px 9.71287px 64.7525px rgba(0, 0, 0, 0.15);
        top: 0;
      }

      &::after {
        width: 190px;
        height: 73px;
        margin-left: -70px;
        margin-bottom: 130px;
      }

      .swiper-wrapper {
        padding-bottom: 100px;
      }
    }
  }

  @media (min-width: 1200px) {
    &__wrap {
      padding: 0px 0px;
    }
  }
}

.banner__images {
  max-width: 545px;
  .swiper {
    overflow: visible;

    &-wrapper {
      overflow: visible;
    }

    &-slide {
      position: relative;
    }

    &-wrapper {
      padding-bottom: 70px;
    }

    &-slide {
      padding: 5px;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}
.swiper-wrap {
  margin-bottom: 40px;
  max-width: 1140px;
  margin: auto;
  .swiper-action {
    bottom: 10px;
    top: inherit;
    width: 36px;
    height: 36px;
    background: #9b26a6;
    border-radius: 100px;
    border: none;
    &::after {
      font-size: 14px;
      color: #fff;
    }
  }
  .swiper-button-prev {
    left: 0px;
  }

  .swiper-button-next {
    right: 0px;
  }

  @media (max-width: 767px) {
    .swiper-button-prev {
      left: 50px;
    }

    .swiper-button-next {
      right: 50px;
    }
  }

  .swiper-wrap-inner {
    border: 1px solid #d9dde3;
    border-radius: 12px;
    max-width: 1032px;
    @media (max-width: 767px) {
      border: none;
    }
  }

  .swiper-pagination {
    bottom: 0px !important;
  }

  .swiper-pagination-bullet-active {
    background: #fb9207;
    width: 27px;
    border-radius: 10.5px;
  }
  @media (min-width: 768px) {
    .swiper-action {
      bottom: 0;
      top: 50%;
      margin-top: -18px;
    }
    .swiper-wrap-inner {
      border: none;
    }
  }
}

.banner {
  padding: 20px 0px;
  .swiper-pagination-bullet-active {
    background: #fb9207;
    width: 27px;
    border-radius: 10.5px;
  }
  &__caption {
    text-align: center;
    margin-bottom: 50px;

    &__title {
      font-family: 'Paytone One';
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 100%;
      margin-bottom: 16px;
    }

    &__content {
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 160%;
      margin-bottom: 24px;
    }

    @media (max-width: 767px) {
      margin-top: 50px;
    }
  }

  &__wrap {
    padding: 0px 15px;
  }

  &__images {
    background: url('../assets/images/tv-frame.png') no-repeat;
    background-size: contain;
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 97%;
      @media (min-width: 1024px) {
        padding: 3px;
      }
    }

    .callbox {
      padding: 8px 12px;
      width: auto;
      height: auto;
      position: absolute;
      left: -69px;
      bottom: 30px;
      z-index: 9;
      background: rgba(255, 255, 255, 0.85);
      border: 4.31683px solid #ffffff;
      box-shadow: 0px 9.71287px 64.7525px rgba(0, 0, 0, 0.15);
      backdrop-filter: blur(18.8861px);
      border-radius: 4px;

      &-title {
        color: #4e5d78;
        font-size: 17px;
        margin: 0px;
      }

      &-number {
        color: #9b26a6;
        font-size: 23px;
        margin: 0px;
      }

      @media (max-width: 767px) {
        height: auto;
        left: -30px;
        bottom: 110px;

        &-title {
          font-size: 15px;
        }

        &-number {
          font-size: 20px;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      background: url('../assets/images/tv-frame-qrcode.png') no-repeat;
      right: 0;
      bottom: 0;
      z-index: 2;
      background-size: contain;
      width: 54px;
      height: 54px;
      box-shadow: 0px 9px 60px rgba(0, 0, 0, 0.15);
      margin-bottom: 60px;
      margin-right: -10px;
    }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     background: url('#{$image-path}/tv-frame-number.png') no-repeat;
    //     left: 0;
    //     bottom: 0px;
    //     z-index: 2;
    //     background-size: contain;
    //     width: 137px;
    //     height: 59px;
    //     @include radius-6;
    //     box-shadow: 0px 9px 60px rgba(0, 0, 0, 0.15);
    //     margin-bottom: 90px;
    //     margin-left: -18px;
    // }

    @media (max-width: 567px) {
      width: 307px;
      height: 258px;
      margin: 0 auto;
    }
  }

  @media (min-width: 567px) {
    &__wrap {
      padding: 0px 18%;
    }
  }

  @media (min-width: 992px) {
    padding: 0px 0px;
    margin-bottom: 0px;
    height: calc(100vh - 79px);

    &__caption {
      text-align: left;
      margin-bottom: 0px;

      &__title {
        font-size: 60px;
        line-height: 100%;
        margin-bottom: 20px;
      }

      &__content {
        font-size: 24px;
        line-height: 132%;
        margin-bottom: 32px;
      }
    }

    &__wrap {
      padding: 0px 30px;
    }

    &__images {
      &::before {
        width: 83px;
        height: 83px;
        margin-top: 27px;
        margin-right: -32px;
        box-shadow: 0px 9.71287px 64.7525px rgba(0, 0, 0, 0.15);
        top: 0;
      }

      &::after {
        width: 190px;
        height: 73px;
        margin-left: -70px;
        margin-bottom: 130px;
      }

      .swiper-wrapper {
        padding-bottom: 100px;
      }
    }
  }

  @media (min-width: 1200px) {
    &__wrap {
      padding: 0px 0px;
    }
  }
}

.banner__images {
  max-width: 545px;
  .swiper {
    overflow: visible;

    &-wrapper {
      overflow: visible;
    }

    &-slide {
      position: relative;
    }

    &-wrapper {
      padding-bottom: 70px;
    }

    &-slide {
      padding: 5px;
    }
  }
}
.call-to-action {
  label {
    position: absolute;
    top: 0px;
    padding-left: 0px;
    color: #12141d;
    font-size: 14px;
    height: 90px;
    margin-left: -15px;
    width: 100%;
    margin-top: -5px;

    span {
      padding-left: 45px;
      position: absolute;
      top: 10px;
    }
  }
  [type='radio'] {
    margin-top: -10px;
    z-index: 1;
  }
}
.radio-sell {
  width: 33%;
  label {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    cursor: pointer;
  }
  [type='radio'] {
    opacity: 0;
  }
}

.bottom-modal [data-rsbs-header='true'] {
  &::before {
    margin-top: -20px;
    background: #fff;
    z-index: 9;
    width: 59px;
  }
}
